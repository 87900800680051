import request from "@/utils/request";

export function getJournal(data) {
  return request({
    method: "post",
    url: "absen/journal",
    data: data
  });
}

export function getTeachingTime(data) {
  return request({
    method: "post",
    url: "lesson_schedule/get_teacher_times",
    data: data
  });
}

export function addJournal(data) {
  return request({
    method: "post",
    url: "journal/create",
    data: data
  });
}

export function editJournal(data, id) {
  return request({
    method: "put",
    url: `journal/update/${id}`,
    data: data
  });
}

export function getListJournal(data) {
  return request({
    method: "post",
    url: "journal/list_by_lesson",
    data: data
  });
}

export function deleteJournal(data) {
  return request({
    method: "post",
    url: "journal/delete",
    data: data
  });
}

export function getDetailSubject(data) {
  return request({
    method: "post",
    url: "journal/detail_lesson",
    data: data
  });
}

export function getJournalForAdmin(data) {
  return request({
    method: "post",
    url: "journal/list_for_admin",
    data: data
  });
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"scrollable":"","max-width":"700","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("journal.edit_journal") : _vm.$t("journal.add_journal"))+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.closeModalHandler(null)}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('DatePicker',{attrs:{"label":_vm.$t('journal.date'),"value":_vm.form.date,"rules":[v => !!v || _vm.$t('app.required')],"disabled":_vm.isEdit,"outlined":"","dense":""},on:{"getValue":v => {
                  _vm.form.date = v;
                  this.getTeachingTimeList();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.isEdit)?_c('v-text-field',{attrs:{"label":_vm.$t('journal.table.hour'),"name":"learning_material","outlined":"","disabled":"","dense":""},model:{value:(_vm.form.hour),callback:function ($$v) {_vm.$set(_vm.form, "hour", $$v)},expression:"form.hour"}}):_c('v-select',{attrs:{"items":_vm.teachingTimeList,"label":_vm.$t('journal.table.hour'),"disabled":!_vm.form.date || _vm.isEdit,"loading":_vm.teachingTimeLoading,"rules":[v => !!v || _vm.$t('app.required')],"item-value":item => `${item.start_time} - ${item.end_time}`,"item-text":item => `${item.start_time} - ${item.end_time}`,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(`${item.start_time} - ${item.end_time}`))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.teachingTimeList.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.form.hour),callback:function ($$v) {_vm.$set(_vm.form, "hour", $$v)},expression:"form.hour"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || _vm.$t('app.required')],"label":_vm.$t('journal.table.title'),"name":"learning_material","outlined":"","dense":""},model:{value:(_vm.form.learning_material),callback:function ($$v) {_vm.$set(_vm.form, "learning_material", $$v)},expression:"form.learning_material"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ButtonUpload',{attrs:{"label":_vm.$t('ppdb.educational_history.upload_document'),"rules":[v => !!v || _vm.$t('app.required')],"loading":_vm.loadingUpload,"depressed":""},on:{"getDocument":r => {
                  this.file = r;
                  this.uploadFile('image');
                }}}),_c('v-row',{staticClass:"my-4"},_vm._l((_vm.form.file),function(file,index){return _c('v-col',{key:index},[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"primary","close":""},on:{"click":function($event){return _vm.openLink(file)},"click:close":function($event){return _vm.setDeleteFile(file, index)}}},[_vm._v(" "+_vm._s(file.replace(/^.*[\\\/]/, ""))+" ")])],1)}),1)],1)],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm.$t("app.description"))+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('quill-editor',{ref:"myQuillEditor",attrs:{"options":_vm.editorOption},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.loadingUpload,"loading":_vm.loadingSubmit,"color":"primary","depressed":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$t("app.save"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
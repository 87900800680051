<template>
  <v-card class="pa-4" :class="g_darkmode ? 'black' : 'grey lighten-5'" flat>
    <v-row no-gutters>
      <v-btn
        class="font-weight-bold mb-3"
        depressed
        @click="
          $router.push({
            name: 'Journal',
            query: {
              school_year: $route.params.school_year,
              grade: $route.params.grade,
              class: $route.params.class
            }
          })
        "
      >
        <v-icon class="mr-2">
          mdi-arrow-left
        </v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </v-row>

    <v-row no-gutters>
      <v-card
        :class="g_darkmode ? 'black' : 'white'"
        class="rounded-lg pa-4"
        flat
        width="100%"
      >
        <v-row no-gutters justify="start">
          <v-col cols="6" sm="3" md="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("subjects.subjects") }}</div>
            <div class="font-weight-bold">
              {{ detailLesson.lesson }}
            </div>
          </v-col>
          <v-col cols="6" sm="3" md="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.grade") }}</div>
            <div class="font-weight-bold">{{ detailLesson.class_grade }}</div>
          </v-col>
          <v-col cols="6" sm="3" md="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.class") }}</div>
            <div class="font-weight-bold">{{ detailLesson.class_name }}</div>
          </v-col>
          <v-col cols="6" sm="3" md="2" class="pl-2">
            <div class="font-weight-light">{{ $t("app.school_year") }}</div>
            <div class="font-weight-bold">{{ detailLesson.semester }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-card :class="g_darkmode ? 'black' : 'white'" flat class="my-4 pa-4">
      <v-row no-gutters>
        <v-btn color="primary" depressed @click="modalJournalHandler()"
          >{{ $t("journal.add_journal") }}
          <v-icon class="ml-2">mdi-plus-circle-outline</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <div style="max-width: 200px">
          <DatePicker
            :label="$t('journal.date')"
            :value="queryData.date"
            outlined
            dense
            hideDetails
            @getValue="
              v => {
                queryData.date = v;
                this.initData();
              }
            "
          />
        </div>
        <v-text-field
          v-model="search"
          :label="$t('app.search')"
          class="ml-2"
          outlined
          dense
          hide-details
          style="max-width: 200px"
          prepend-inner-icon="mdi-magnify"
        />
      </v-row>
    </v-card>

    <v-data-table
      v-model="selectedDeleteJournal"
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      :search="search"
      class="elevation-0"
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <v-row class="my-4" v-if="item.file && item.file.length > 0">
            <v-col v-for="(row, index) in item.file" :key="index" cols="12">
              <v-chip
                color="primary"
                close
                class="mr-2"
                @click="openLink(row.file)"
              >
                {{ row.file.replace(/^.*[\\\/]/, "") }}
              </v-chip>
            </v-col>
          </v-row>
          <div v-if="item.description">
            <v-divider class="my-2"></v-divider>
            <span v-html="item.description"></span>
          </div>
        </td>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row no-gutters align="center">
          <v-btn
            dark
            fab
            x-small
            depressed
            color="primary"
            class="mr-2"
            @click="editJournal(item)"
          >
            <v-icon size="20">mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            dark
            fab
            x-small
            depressed
            color="error"
            @click="setDeleteJournal(item)"
          >
            <v-icon size="20">mdi-delete-forever</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <JournalForm
      :dialog="modalJournal"
      :lessonId="$route.params.lesson"
      :closeModal="
        () => {
          modalJournal = false;
          selectedJournal = {};
          initData();
        }
      "
      :tempEdit="selectedJournal"
      :teacher="g_user.employee_id"
    />

    <ModalConfirm
      :title="$t('journal.confirm_delete_title')"
      :close="() => (this.confirmDelete = false)"
      :loading="loadingDelete"
      :isOpen="confirmDelete"
      :save="deleteHandler"
    >
      {{ $t("journal.confirm_delete") }}
    </ModalConfirm>
  </v-card>
</template>

<script>
import {
  getDetailSubject,
  getListJournal,
  deleteJournal
} from "@/api/admin/absent/journal";
import { mapGetters } from "vuex";
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import DatePicker from "@/components/DatePicker";
import JournalForm from "./JournalForm";
import ModalConfirm from "@/components/ModalConfirm";

export default {
  metaInfo: {
    title: i18n.t("routes.journal"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    DatePicker,
    JournalForm,
    ModalConfirm
  },
  computed: mapGetters(["g_master_data_siswa", "g_user", "g_darkmode"]),
  data() {
    return {
      detailLesson: {
        class_grade: "",
        class_name: "",
        lesson: "",
        semester: ""
      },
      loading: false,
      length: 0,
      total: 0,
      modalJournal: false,
      selectedJournal: {},
      selectedDeleteJournal: [],
      confirmDelete: false,
      loadingDelete: false,
      tableHeaders: [
        {
          text: i18n.t("journal.table.hour"),
          value: "hour"
        },
        {
          text: i18n.t("journal.table.title"),
          value: "learning_material"
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          width: 150
        }
      ],
      queryData: {
        search: "",
        date: "",
        teacher: "",
        lesson: this.$route.params.lesson,
        classroom: this.$route.params.class
      },
      dataTable: [],
      search: ""
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData.date = moment().format("YYYY-MM-DD");
      this.queryData.teacher = this.g_user.employee_id;
    });

    getDetailSubject({
      classroom: this.$route.params.class,
      lesson: this.$route.params.lesson,
      school_year: this.$route.params.school_year
    }).then(res => {
      if (res.data.code) {
        this.detailLesson = res.data.data;
      }
    });
  },
  methods: {
    openLink: url => window.open(url, "_blank"),
    deleteHandler() {
      this.loadingDelete = true;
      deleteJournal({ id: this.selectedDeleteJournal })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: i18n.t("app.success_delete"),
              color: "success"
            });

            this.confirmDelete = false;
            this.deleteHistory = null;
            this.selectedDeleteJournal = [];
            this.initData();
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loadingDelete = false;
        })
        .catch(() => (this.loadingDelete = false));
    },
    editJournal(item) {
      this.modalJournal = true;
      this.selectedJournal = item;
    },
    setDeleteJournal(item) {
      this.selectedDeleteJournal = [item.id];
      this.confirmDelete = true;
    },
    modalJournalHandler() {
      this.modalJournal = true;
    },
    initData() {
      this.loading = true;
      this.dataTable = [];
      getListJournal(this.queryData)
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    }
  }
};
</script>

<style></style>

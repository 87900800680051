<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="700"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ isEdit ? $t("journal.edit_journal") : $t("journal.add_journal") }}
        <v-spacer />
        <v-icon @click="closeModalHandler(null)">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row class="mt-4">
            <v-col cols="12" sm="6">
              <DatePicker
                :label="$t('journal.date')"
                :value="form.date"
                :rules="[v => !!v || $t('app.required')]"
                :disabled="isEdit"
                outlined
                dense
                @getValue="
                  v => {
                    form.date = v;
                    this.getTeachingTimeList();
                  }
                "
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-if="isEdit"
                v-model="form.hour"
                :label="$t('journal.table.hour')"
                name="learning_material"
                outlined
                disabled
                dense
              ></v-text-field>
              <v-select
                v-else
                v-model="form.hour"
                :items="teachingTimeList"
                :label="$t('journal.table.hour')"
                :disabled="!form.date || isEdit"
                :loading="teachingTimeLoading"
                :rules="[v => !!v || $t('app.required')]"
                :item-value="item => `${item.start_time} - ${item.end_time}`"
                :item-text="item => `${item.start_time} - ${item.end_time}`"
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ `${item.start_time} - ${item.end_time}` }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ teachingTimeList.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.learning_material"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('journal.table.title')"
                name="learning_material"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <ButtonUpload
                :label="$t('ppdb.educational_history.upload_document')"
                :rules="[v => !!v || $t('app.required')]"
                :loading="loadingUpload"
                depressed
                @getDocument="
                  r => {
                    this.file = r;
                    this.uploadFile('image');
                  }
                "
              />

              <v-row class="my-4">
                <v-col v-for="(file, index) in form.file" :key="index">
                  <v-chip
                    color="primary"
                    close
                    class="mr-2"
                    @click="openLink(file)"
                    @click:close="setDeleteFile(file, index)"
                  >
                    {{ file.replace(/^.*[\\\/]/, "") }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-6"></v-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2" class="mb-2">
              {{ $t("app.description") }}
            </v-col>
            <v-col cols="12" sm="10">
              <quill-editor
                ref="myQuillEditor"
                v-model="form.description"
                :options="editorOption"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters class="mt-3">
          <v-spacer />
          <v-btn
            :disabled="!valid || loadingUpload"
            :loading="loadingSubmit"
            color="primary"
            depressed
            @click="submit()"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { uploadFile, deleteFile } from "@/api/admin/admin";
import { sliceAwsPath } from "@/utils/uploadS3";
import DatePicker from "@/components/DatePicker";
import ButtonUpload from "@/components/ButtonUpload";

import {
  addJournal,
  getTeachingTime,
  editJournal
} from "@/api/admin/absent/journal";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import katex from "katex";
import "katex/dist/katex.min.css";

export default {
  props: {
    dialog: Boolean,
    lessonId: [String, Number],
    closeModal: Function,
    tempEdit: Object,
    teacher: [String, Number]
  },
  components: {
    quillEditor,
    DatePicker,
    ButtonUpload
  },
  data() {
    return {
      // allowedExt: ".pdf, .ppt, .pptx, .xls, .xlsx, .doc, .docx",
      valid: false,
      moduleFileName: "RECAP",
      basicCompetenciesList: [],
      loadingCompetencies: false,
      file: null,
      loadingUpload: false,
      loadingSubmit: false,
      teachingTimeLoading: false,
      isEdit: false,
      teachingTimeList: [],
      form: {
        teacher: this.teacher,
        lesson: this.$route.params.lesson,
        classroom: this.$route.params.class,
        hour: [],
        date: "",
        learning_material: "",
        description: "",
        file: []
      },
      tempDeleteFile: [],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["formula", "link"]
          ]
        }
      }
    };
  },
  watch: {
    async dialog(nv) {
      if (nv) {
        this.isEdit = false;
        this.tempDeleteFile = [];
        setTimeout(() => {
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          if (this.tempEdit.id) {
            this.isEdit = true;
            const r = this.tempEdit;
            const form = this.form;
            form.id = r.id;
            form.hour = r.hour;
            form.date = r.date;
            form.learning_material = r.learning_material;
            form.description = r.description;
            form.file = r.file.map(r => r.file);
          } else {
            this.isEdit = false;
          }
        }, 100);
      }
    }
  },
  created() {
    window.katex = katex;
  },
  methods: {
    openLink: url => window.open(url, "_blank"),
    resetForm() {
      this.form.hour = [];
      this.form.date = null;
      this.form.learning_material = "";
      this.form.description = "";
      this.form.file = [];
    },
    getTeachingTimeList() {
      this.teachingTimeLoading = true;
      const query = {
        lesson: this.$route.params.lesson,
        classroom: this.$route.params.class,
        date: this.form.date
      };
      getTeachingTime(query)
        .then(res => {
          if (res.data.code) this.teachingTimeList = res.data.data;
          this.teachingTimeLoading = false;
        })
        .catch(() => (this.teachingTimeLoading = false));
    },
    setDeleteFile(item, index) {
      this.form.file.splice(index, 1);
      if (this.tempDeleteFile.filter(r => r == item)) {
        this.tempDeleteFile.push(item);
      }
    },
    async closeModalHandler(param) {
      this.resetForm();
      if (param == "save") {
        this.closeModal("save");
      } else {
        this.closeModal();
        if (this.form.url && !this.tempEdit.id) {
          const path = await sliceAwsPath(this.form.url);
          await deleteFile(path, this.moduleFileName);
        }
      }
    },
    uploadFile(param) {
      this.loadingUpload = true;
      let bodyImage = new FormData();
      bodyImage.set("path", `journal/${param}`);
      bodyImage.set("module", this.moduleFileName);
      bodyImage.append("file", this.file);
      uploadFile(bodyImage)
        .then(res => {
          if (res.data.code) {
            this.form.file.push(res.data.data.path);
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loadingUpload = false;
        })
        .catch(() => {
          this.loadingUpload = false;
        });
    },
    async submit() {
      console.log(this.tempDeleteFile);
      if (this.$refs.form.validate()) {
        this.loadingSubmit = true;

        if (this.tempDeleteFile && this.tempDeleteFile.length > 0) {
          this.tempDeleteFile.map(async r => {
            const path = await sliceAwsPath(r);
            await deleteFile(path, this.moduleFileName);
          });
        }

        if (this.tempEdit.id) {
          const f = this.form;
          const data = {
            learning_material: f.learning_material,
            description: f.description,
            file: f.file
          };
          editJournal(data, f.id)
            .then(async res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_edit"),
                  color: "success"
                });
                await this.$refs.form.reset();
                this.closeModalHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        } else {
          addJournal(this.form)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.$refs.form.reset();
                this.closeModalHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        }
      } else {
        this.$nextTick(() => {
          const element = document.querySelector(".v-input.error--text");
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }
        });
      }
    }
  }
};
</script>
